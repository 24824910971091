function SliderCtrl() {

	this.init = function() {
    this.translateX = 0;
    this.lastTranslateX = 0;
    this.sliderMove = false;
		var slidesPerView = this.DOM.wrapper.data('slides') || 1;
    var $$slides = $('.slider__slide', this.DOM.wrapper);
    if ($$slides.length === 1) return false;
    var $wrapper = $('.slider__wrapper', this.DOM.wrapper);
		this.swiper = new Swiper(this.DOM.wrapper, {
      slidesPerView: slidesPerView,
      spaceBetween: slidesPerView > 1 ? 20 : 0,
      wrapperClass: 'slider__wrapper',
      autoplay: {
				disableOnInteraction: true,
        delay: 6000,
      },
      navigation: {
        disabledClass: 'slider__arrow--disabled',
        nextEl: $('.slider__arrow--next', this.DOM.wrapper),
        prevEl: $('.slider__arrow--prev', this.DOM.wrapper)
      },
			pagination: {
				bulletClass: 'slider__bullet',
				bulletActiveClass: 'slider__bullet--active',
				el: this.DOM.wrapper.find('.slider__bullets'),
				type: "bullets",
				clickable: true,
      },
      on: {
        sliderMove: function() {
          this.sliderMove = true;
          this.move = true;
        }.bind(this),
        touchEnd: function() {
          this.sliderMove = false;
        }.bind(this),
        setTranslate: function(x) {
          if (typeof(this.translateX) !== 'undefined') this.lastTranslateX = this.translateX;
          this.translateX = x;
          if (this.sliderMove) return;
          var ease = this.move ? Expo.easeOut : Expo.easeInOut;
          this.move = false;
          TweenLite.fromTo($wrapper, 0.55, {
            x: this.lastTranslateX,
          }, {
            x: x,
            onUpdate: function() {
              $$slides
                .removeClass('swiper-slide-active')
                .eq(this.swiper.snapIndex)
                .addClass('swiper-slide-active')
              }.bind(this),
            ease: ease,
          })
        }.bind(this),
      },
    });
		this.DOM.wrapper.hover(function () {
			this.swiper.autoplay.stop();
		}, function () {
			this.swiper.autoplay.start();
		});
	}

}

SliderCtrl.prototype = new BaseClass();
SliderCtrl.prototype.constructor = SliderCtrl;