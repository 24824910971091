function WpformsCtrl() {

	this.init = function() {
    var param = UTILS.getParameterByName("contact_reason");
    var contactReasonFieldId = "wpforms-151-field_5";
    if (param) {
      $("#" + contactReasonFieldId + "_" + param).attr("checked", true);
    }
  }

}

WpformsCtrl.prototype = new BaseClass();
WpformsCtrl.prototype.constructor = WpformsCtrl;