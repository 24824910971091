var EVENTS = {
    pageReady: function() {

        CONST.ctrlManager = new CtrlManager();

        (function animloop(){
            CONST.raf = requestAnimFrame(animloop);
            EVENTS.onRafRender();
        })();
       
        $(window)
            .on({
                resize: EVENTS.onResize,
                scroll: EVENTS.onScroll
            })
            .on('mousewheel', EVENTS.onMouseWheel)

        CONST.ctrlManager.init()

        setTimeout(UTILS.loadScript.bind(null, CONST.zendDeskSrc, 'ze-snippet'), 2000);

    },
    onScroll: function() {
        CONST.ctrlManager.onScroll()
    },
    onResize: function() {
        CONST.ctrlManager.onResize()
    },
    onRafRender: function() {
        CONST.ctrlManager.onRafRender()
    },
    onMouseWheel: function(event) {
        CONST.ctrlManager.onMouseWheel()
    }
};