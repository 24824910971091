function BaseClass() {
	
	this.DOM = {};

	var methodArray = [
		'init',
		'changeState',
		'resize',
		'raf',
		'onMouseWheelUp',
		'onMouseWheelDown',
		'scroll',
		'destroy',
		'ready'
	]
	
	for(var i=0;i<methodArray.length;i++) this[methodArray[i]] = function() {};

	this.initBaseClass = function() {

        this.init()

	}

	this.setWrapper = function(wrapper) {

		this.DOM.wrapper = wrapper
	
	}

	this.destroyBaseClass = function() {

		this.destroy()
	
	}

}