var UTILS = {
    captureMouse: function(e) {
        var t = {
                x: 0,
                y: 0,
                event: null
            },
            o = document.body.scrollLeft,
            a = document.documentElement.scrollLeft,
            i = document.body.scrollTop,
            n = document.documentElement.scrollTop,
            l = e.offsetLeft,
            r = e.offsetTop;
        return window.addEventListener && e.addEventListener("mousemove", function(e) {
            var c, s;
            e.pageX || e.pageY ? (c = e.pageX, s = e.pageY) : (c = e.clientX + o + a, s = e.clientY + i + n), c -= l, s -= r, t.x = c, t.y = s, t.event = e
        }, !1), t
    },
    scrollTo: function (offset, speed, wrapper) {
        
        if (offset == "end") {
            offset = $(document).height() - $(window).height()  
        }

        if (offset == "top") {
            offset = 0
        }
        
        if (!wrapper) wrapper = window  

        if (speed == undefined) speed = 1.5
        
        TweenLite.to(wrapper, speed, {
            scrollTo:{
                y: offset,
                autoKill: false
            },
            ease: Expo.easeInOut
        });
    },
    randomString: function() {
        return Math.random().toString(36).substring(7);
    },
    preloadImages: function(imageArray, callback, index) {
        index = index || 0;
        if (imageArray && imageArray.length > index) {
            var img = new Image ();
            img.onload = function() {
                UTILS.preloadImages(imageArray, callback, index + 1);
            }
            img.src = imageArray[index]
        } else {
            callback()
        }
    },
    hexToRgb: function(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    },
    getJSONPromise: function(url) {
        return new Promise(function(resolve, reject) {
            $.getJSON(url, resolve)
        })
    },
    setTemplateState: function(state) {
        $('html').attr('data-template-state', state)
    },
    getTemplate: function() {
        return $('html').attr('data-template')
    },
    getParameterByName: function(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    loadScript: function (url, id) {
      var script = document.createElement('script'),
        scripts = document.getElementsByTagName('script')[0];
      if (id) {
        script.setAttribute('id', id);
      }
      script.src = url;
      scripts.parentNode.insertBefore(script, scripts);
    }
}

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
          };
})();