var GsapAnimations = function() {
	
	var fadeIn = function(elem, callback) {

		var timeline = new TimelineMax({
			onComplete: callback || function() {}
		})

		timeline.fromTo(elem, 1, {
			opacity: 0
		}, {
			opacity: 1,
			clearProps: 'opacity'
		})
	}

	var fadeOut = function(elem, callback) {

		var timeline = new TimelineMax({
			onComplete: callback || function() {}
		})

		timeline.fromTo(elem, 1, {
			opacity: 1
		}, {
			opacity: 0,
			clearProps: 'opacity'
		})
	}

	return {
		fadeIn: fadeIn,
		fadeOut: fadeOut
	}

}