function GsapMask(element) {

	var nameSpace = 'GsapMask'
	var element = 'jquery' in element ? element.get(0) : element
	var wrappedElement
	var randomClass
	var methods = {}
	var methodsName = ['slideUp', 'slideRight', 'slideDown', 'slideLeft']
	var elementStyle;
	var sheet;

	var createCSSRule = function() {
		sheet = getStyle()
	}

	var addCSSRule = function(selector, rules, index) {
		var index = index || 0
		if ('insertRule' in sheet) {
			sheet.insertRule(selector + '{' + rules + '}', index);
		} else if ('addRule' in sheet) {
			sheet.addRule(selector, rules, index);
		}
	}

	var getStyle = function() {

		if (!window[nameSpace + '-stylesheet']) {
			var style = document.createElement('style');
			style.setAttribute('class', nameSpace)
			style.setAttribute('media', 'screen')
			style.appendChild(document.createTextNode(''));
			document.head.appendChild(style);
			window[nameSpace + '-stylesheet'] = style.sheet
			return style.sheet;
		} else {
			return window[nameSpace + '-stylesheet']
		}
		
	}

	var getRandomClass = function() {
		return nameSpace + '-' + UTILS.randomString(15)
	}

	var wrapElement = function() {

		var cssElementValue = {
			'style': 0,
			'position': 0,
			'top': 0,
			'right': 0,
			'bottom': 0,
			'left': 0,
			'margin-top': 0,
			'margin-right': 0,
			'margin-bottom': 0,
			'margin-left': 0,
			'padding-top': 0,
			'padding-right': 0,
			'padding-bottom': 0,
			'padding-left': 0,
			'float': 0,
			'display': 0,
			'width': 0,
			'height': 0,
			'color': 'inherit',
			'background-color': 0
		}

		var computedStyle = window.getComputedStyle(element, null)
		for (var c in cssElementValue) cssElementValue[c] = computedStyle.getPropertyValue(c)			

		randomClass = getRandomClass()
		wrappedElement = UTILS.wrap(element, '<span class=\'' + randomClass + '\'></span>')				
		wrappedElement.style.overflow = 'hidden'
		
		element.removeAttribute('style')

		// SVG DISPLAY EXCEPTION
		
		if (UTILS.matches(element, 'svg')) cssElementValue['display'] = 'inline-block'
		
		// CHILDREN MARGIN EXCEPTION

		if (element.children.length > 0) {
			
			var firstChildCS = window.getComputedStyle(element.children[0], null)
			var lastChildCS = window.getComputedStyle(element.children[element.children.length - 1], null)
		 	
		 	var marginTop = firstChildCS.getPropertyValue('margin-top')
		
			if (marginTop.replace('px', '') < 0) {
				cssElementValue['height'] = parseInt(cssElementValue['height'].replace('px', '')) + Math.abs(marginTop.replace('px', '')) + 'px'
				cssElementValue['margin-top'] = parseInt(cssElementValue['margin-top'].replace('px', '')) + parseInt(marginTop.replace('px', '')) + 'px'
				addCSSRule('[class*=\'' + randomClass + '\'] > * > *:first-child', 'margin-top: 0!important')
			}

			var marginBottom = lastChildCS.getPropertyValue('margin-bottom')
			
			if (marginBottom.replace('px', '') < 0) {
				cssElementValue['height'] = parseInt(cssElementValue['height'].replace('px', '')) + Math.abs(marginBottom.replace('px', '')) + 'px'
				addCSSRule('[class*=\'' + randomClass + '\'] > * > *:last-child', 'margin-bottom: 0!important')
			}
			
		}

		for (var c in cssElementValue) {
			
			if (
				c == 'top' && cssElementValue['position'] == 'absolute'
				|| c == 'left' && cssElementValue['position'] == 'absolute'
			) {
				element.style[c] = '0'
				wrappedElement.style[c] = cssElementValue[c]
			} else if (c == 'background-color') {
				element.style[c] = cssElementValue[c]
				wrappedElement.style[c] = 'none'
			} else {
				element.style[c] = 'auto'
				wrappedElement.style[c] = cssElementValue[c]
			}
			
		}

		element.style.opacity = 1

	}

	var unWrapElement = function() {

		if (
			wrappedElement
			&& wrappedElement.parentNode
		) {

			UTILS.unWrap(wrappedElement)
		}	

		if (elementStyle) {
			element.setAttribute('style', elementStyle)
		} else {
			element.removeAttribute('style')
		}

	}

	var destroy = function() {
		unWrapElement()
	}

	for (var m in methodsName) {

		methods[methodsName[m]] = (function(method) {

			var method = method
	
			return function(params) {

				var params = params || {}
				var reverse = params.reverse || false
				var time = params.time || .8
				var ease = params.ease || Expo.easeOut
				var onComplete = params.onComplete || function() {}
				
				if (
					!UTILS.matches(element.parentNode, '.' + randomClass)
				) {
					elementStyle = element.getAttribute('style')
				}

				TweenMax.killTweensOf(element)
				TweenMax.set(element, {
					opacity: 0
				})

				var onStart = function() {

					if (
						!UTILS.matches(element.parentNode, '.' + randomClass)
					) {
						wrapElement()
					}
	
				}

				var timeline = new TimelineMax({
					onStart: onStart,
					onComplete: (function() {
						return function() {
							unWrapElement()
							onComplete()
						}
					})()
				})

				timeline.set(element, {
					x: '0%',
					y: '0%'
				})

				if (!reverse) {
				
					timeline.fromTo(
						element,
						time,
						getTweenFromData(method),
						UTILS.extend(getTweenToData(method), {
							clearProps: 'transform',
							ease: ease
						})
					)
				
				} else {
	
					timeline.fromTo(
						element,
						time,
						getTweenToData(method),
						UTILS.extend(getTweenFromData(method), {
							clearProps: 'transform',
							ease: ease
						})
					)
					
				}

				return timeline
			
			}
			
		})(methodsName[m])

	}

	var getTweenFromData = function(method) {

		var o;

		switch(method) {
			case('slideUp'):
				o = {y: '100%'}	
			break;
			case('slideRight'):
				o = {x: '100%'}	
			break;
			case('slideDown'):
				o = {y: '-100%'}	
			break;
			case('slideLeft'):
				o = {x: '-100%'}	
			break;
		}

		return o

	}

	var getTweenToData = function(method, ease) {

		var o;

		switch(method) {
			case('slideUp'):
			case('slideDown'):
				o = {y: '0%'}	
			break;
			case('slideRight'):
			case('slideLeft'):
				o = {x: '0%'}	
			break;
		}

		o.ease = ease

		return o

	}

	createCSSRule()

	return {
		destroy: destroy,
		slideUp: methods.slideUp,
		slideRight: methods.slideRight,
		slideDown: methods.slideDown,
		slideLeft: methods.slideLeft
	}

}