function AsideCtrl() {

	this.init = function() {
    this.$menu = $('.menu', this.DOM.wrapper);
    this.$$items = $('.menu-item', this.$menu);
    this.checkBtnLength()
  }

  this.resize = function() {
    this.checkBtnLength();
  }

  this.checkBtnLength = function() {
    var wWidth = $(window).width();
    if (wWidth < 992) {
      var w = 0;
      this.$$items.each(function (index, $el) {
        w += $($el).outerWidth(true, true)
      });
      w = Math.round(w);

      if (w > wWidth) {
        this.$menu.addClass('menu--masked');
      } else {
        this.$menu.removeClass('menu--masked');
      }
    } else {
      this.$menu.removeClass('menu--masked');
    }
  }
}

AsideCtrl.prototype = new BaseClass();
AsideCtrl.prototype.constructor = AsideCtrl;