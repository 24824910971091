'use strict';

function CtrlManager(params) {
	
    var params = params || {}

	var ctrl = [];
	var oldCtrl = [];

    var wrapper = {};
    var wrapperIndex = {};

    var getCtrlById = function(ID) {
        for (var c in ctrl) if (ctrl[c].ID == ID) return ctrl[c]
    }
    
    var checkCtrl = function(elem) {
        if (!window[elem.data('ctrl')]) {
            elem.attr('data-state', 'ready')
            return false;
        }
        return true;
    }
    
    var create = function() {

        $('[data-ctrl]').each(function(i) {

            if (checkCtrl($(this))) {
                
                $(this)
                    .attr('data-index', i)
                    .attr('data-state', 'ready')
                
                var currentCtrl = new window[$(this).data('ctrl')];
                currentCtrl.DOM.wrapper = $(this)
                ctrl.push(currentCtrl);
                
                (wrapper[$(this).data('ctrl')] = wrapper[$(this).data('ctrl')] || []).push($(this))
            }
        
        });

    }

    var init = function() {
        
        create()
        callMethodOnCtrl(ctrl, 'initBaseClass')
    
    }

    var callMethodOnCtrl = function(array, method, params) {
       
        for (var t = 0; t < array.length; t++) {
            
            var name = array[t].constructor.name

            if (method == 'initBaseClass') {
                wrapperIndex[name] = wrapperIndex[name] != undefined ? wrapperIndex[name] + 1 : 0
                var selector = wrapper[name][wrapperIndex[name]]
                array[t].DOM.wrapper = selector
            }

            array[t][method]()

        }

    }

    // INTERACTION EVENTS

    var onScroll = function() {
       callMethodOnCtrl(ctrl, 'scroll')
    }
    
    var onResize = function() {
       callMethodOnCtrl(ctrl, 'resize')
    }

    var onMouseWheel = function(delta) {
       callMethodOnCtrl(ctrl, delta > 0 ? 'onMouseWheelUp' : 'onMouseWheelDown')
    }
    
    var onRafRender = function() {
       // callMethodOnCtrl(ctrl, 'raf')
    }

    return {
        onScroll: onScroll,
        onResize: onResize,
        onRafRender: onRafRender,
        onMouseWheel: onMouseWheel,
        init: init,
        getCtrlById: getCtrlById
    }

}

/**
 * Hack in support for Function.name for browsers that don't support it.
 * IE, I'm looking at you.
**/
if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
  Object.defineProperty(Function.prototype, 'name', {
    get: function () {
      var funcNameRegex = /function\s([^(]{1,})\(/;
      var results = (funcNameRegex).exec((this).toString());
      return (results && results.length > 1) ? results[1].trim() : "";
    },
    set: function (value) { }
  });
}